import React from 'react'
import styled from 'styled-components';
import MailImage from '../images/mail.png'
import LocationImage from '../images/location.png'
import PhoneImage from '../images/phone.png'
import WhatsappImage from '../images/wpp.png'
import { Link } from 'gatsby'
import { device } from './Device'

const Container = styled.div`
  width: 50%;
  float: right;
  height: 900px;
  @media ${device.tablet} {
    width: 100%;
    float: none;
  }

  @media ${device.mobileM} {
    height: 970px;
  }
`

const Content = styled.div`
  padding: 50px;
`

const Title = styled.h2`
  font-family: Playfair Display;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 53px;
  letter-spacing: -0.01em;

  color: #3E3E3E;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.01);
`

const InfoBox = styled.div`
  margin-top: 50px;
`

const BoxTitle = styled.h3`
  font-family: Google Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #3E3E3E;
  float: left;
  margin-left: 20px; 
  padding-top: 12px;
`

const InfoGroup = styled.div`
  clear: both;
  margin-left: 70px;
`

const IconImage = styled.img`
  float: left;
`

const HowToLocateLink = styled(Link)`
margin-left: 70px;
font-family: Google Sans;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 21px;
/* identical to box height, or 150% */

text-align: center;

/* Base / Logo */

color: #8B7C70;
text-decoration: underline;
`

const BoldSpan = styled.span`
font-family: Google Sans;
font-weight: 500;
line-height: 22px;
`

const MailLink = styled.a`
font-family: Google Sans;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 21px;
/* identical to box height, or 150% */

text-align: center;
text-decoration-line: underline;

color: #2D6AC7;

`
const WhatsAppBox = styled.div`
  display: flex;
  align-items:center;  
`

const WhatsApp = styled.img`
  float: left;
  width: 32px;
`

const Separator = styled.div`
  height: 1px;
  width: 60%;
  background: #F2F2F2;
  margin-top: 20px;
  margin-left: 70px
`

class LocationInfo extends React.Component {
  render() {
    return (
      <Container>
        <Content>
          <Title>Onde estamos</Title>
          <InfoBox>
            <IconImage src={LocationImage} />
            <BoxTitle>Endereço</BoxTitle>
            <InfoGroup>
              <p>De segunda a sexta das 9h até as 17h, todos os serviços no endereço:</p><br/>
              <BoldSpan>Centro Empresarial Ceilândia</BoldSpan><br />
              <span>CNM 1 Bloco I Lote 03, 4° Andar, sala 401/402</span><br />
              <span>Ceilândia - Brasília/DF CEP 72215-509</span>
              <HowToLocateLink target="_blank" to="https://www.google.com/maps/dir//-15.8178198,-48.1022207/@-15.817696,-48.102099,19z?hl=pt-BR">Como Chegar</HowToLocateLink>
              <br /><br /><p>Finais de semana e feriados funcionamos para registros de <b>óbito</b> e <b>nascimento</b> de 9h 17h dentro do <a target="_blank" href="https://goo.gl/maps/EG9CmyespVaaBWfS8">Hospital Regional de Ceilândia</a>.</p>
            </InfoGroup>
            <br />
            
            <Separator />
          </InfoBox>

          <InfoBox>
            <IconImage src={PhoneImage} />
            <BoxTitle>Telefones</BoxTitle>
            <InfoGroup>
              <BoldSpan>(61) 3581-2795</BoldSpan><br />
              <BoldSpan>(61) 3581-6607</BoldSpan><br /><br />
              <MailLink target="_blank" href="https://wa.me/556185094174">
                <WhatsAppBox>
                  <WhatsApp src={WhatsappImage} />
                  <span>WhatsApp: +55 61 8509-4174</span>
                </WhatsAppBox>
                <WhatsAppBox>
                  <WhatsApp src={WhatsappImage} />
                  <span>WhatsApp: +55 61 8182-5119</span>
                </WhatsAppBox>
       <WhatsAppBox>
                  <WhatsApp src={WhatsappImage} />
                  <span>WhatsApp: +55 61 98515-0952</span>
                </WhatsAppBox>
              </MailLink>

            </InfoGroup>
            <Separator />
          </InfoBox>
          <InfoBox>
            <IconImage src={MailImage} />
            <BoxTitle>E-mail</BoxTitle>
            <InfoGroup>
              <MailLink href="mailto:7rcrtd@gmail.com?subject=Contato%20Site">7rcrtd@gmail.com</MailLink>
            </InfoGroup>

          </InfoBox>
        </Content>
      </Container>
    )
  }
}

export default LocationInfo;
